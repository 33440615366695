import React, { useState } from "react";
import { SectionHeading } from "components/misc/Headings.js";
import styled from "styled-components";
import tw from "twin.macro";
import {
  FaLinkedin,
  FaEnvelope,
  FaGlobe,
  FaInstagram,
  FaFacebook,
  FaPhone,
  FaEye,
  FaDownload,
} from "react-icons/fa";
import logo from "./../images/spartan/spartan_app_solutions_logo3_head.png";

const Container = tw.div`relative bg-gray-900 text-white min-h-screen flex flex-col items-center justify-center px-6`;
const Heading = tw(
  SectionHeading
)`w-full text-orange-spartan1 text-center mb-10`;

const ContactSection = tw.div`flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-16 text-center w-full max-w-4xl`;
const ContactCard = styled.div`
  ${tw`flex flex-col items-center bg-gray-800 p-6 rounded-lg shadow-lg text-center`}
  width: 400px;
`;

const Name = tw.h2`text-2xl font-semibold text-white`;
const Role = tw.p`text-gray-400 text-sm`;
const ContactLink = tw.a`flex items-center justify-center space-x-2 text-orange-spartan1 hover:text-orange-spartan2 transition mt-2`;

const PhoneContainer = styled.div`
  ${tw`flex items-center space-x-2 mt-2 cursor-pointer text-gray-300 hover:text-orange-spartan1 transition`}
`;

const HiddenPhone = styled.span`
  ${tw`text-gray-300 font-semibold transition-opacity duration-300`}
  opacity: ${({ show }) => (show ? "1" : "0")}; /* Smooth fade-in effect */
`;

const SaveContactButton = tw.button`mt-4 flex items-center space-x-2 px-4 py-2 bg-orange-spartan2 text-white font-semibold rounded-lg shadow-md hover:bg-orange-spartan2 transition`;

const SocialContainer = tw.div`flex justify-center space-x-4 mt-6`;
const SocialLink = tw.a`text-white text-2xl hover:text-orange-spartan1 transition transform hover:scale-110`;

const LogoImg = tw.img`w-8 sm:w-12 mb-2 sm:mb-0`;
const LogoContainer = tw.div`flex flex-col items-center justify-center sm:flex-row sm:justify-start`;

// Function to Generate Obfuscated Phone Number
const getPhoneNumber = (part1, part2, part3) => {
  return `${String.fromCharCode(...part1)}-${String.fromCharCode(
    ...part2
  )}-${String.fromCharCode(...part3)}`;
};

const SocialIconButton = tw.button`mt-4 flex items-center space-x-2 px-4 py-2 bg-orange-spartan1 text-white font-semibold   shadow-md hover:bg-orange-spartan2 transition`;


export default () => {
  const [showPhoneMohammed, setShowPhoneMohammed] = useState(false);
  const [showPhoneCarlos, setShowPhoneCarlos] = useState(false);

// Function to generate & download vCard
// BEGIN:VCARD
// VERSION:3.0
// N:Doe;John;;;
// FN:John Doe
// ORG:Example Company
// TITLE:Software Developer
// TEL;TYPE=WORK,VOICE:(111) 555-1212
// EMAIL:john.doe@example.com
// URL:https://www.example.com
// END:VCARD

  const downloadVCard = (name, role, email, phone,website,org) => {
    const vCardData = `BEGIN:VCARD
VERSION:3.0
FN:${name}
ORG:${org}
TITLE:${role}
EMAIL:${email}
TEL:${phone}
URL:${website}
END:VCARD`;

    const blob = new Blob([vCardData], { type: "text/vcard" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${name.replace(" ", "_")}.vcf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <LogoContainer>
        <LogoImg src={logo} alt="Spartan App Solutions logo" />
      </LogoContainer>
      <Heading>
        Welcome to <br /> Spartan App Solutions
      </Heading>

      <ContactSection>
        <ContactCard>
          <Name>Mohammed Moufakkir</Name>
          <Role>Co-Founder & President</Role>
          <SocialLink
          href="https://www.linkedin.com/in/mohammed-moufakkir-10390b54"
          target="_blank"
          aria-label="LinkedIn"
        >
          <SocialIconButton>
          <FaLinkedin />
          </SocialIconButton>
        </SocialLink>

          <ContactLink href="mailto:mmoufakkir@spartanappsolutions.com">
            <FaEnvelope /> <span>mmoufakkir@spartanappsolutions.com</span>
          </ContactLink>

          <SaveContactButton
            onClick={() =>
              downloadVCard(
                "Mohammed Moufakkir",
                "Co-Founder & President",
                "mmoufakkir@spartanappsolutions.com",
                getPhoneNumber([55, 50, 55], [53, 54, 48], [57, 57, 54, 57]),
                "https://www.spartanappsolutions.com",
                "Spartan App Solutions Inc"
              )
            }
          >
            <FaDownload />
            <span>Save Contact</span>
          </SaveContactButton>
        </ContactCard>

        {/* <ContactCard>
          <Name>Carlos Perez</Name>
          <Role>Co-Founder & CTO</Role>
          <ContactLink href="mailto:cperez@spartanappsolutions.com">
            <FaEnvelope /> <span>cperez@spartanappsolutions.com</span>
          </ContactLink>

          <SaveContactButton
            onClick={() =>
              downloadVCard(
                "Carlos Perez",
                "Co-Founder & CTO",
                "cperez@spartanappsolutions.com",
                getPhoneNumber([55, 50, 55], [53, 54, 48], [54, 52, 55, 52]),
                "https://www.spartanappsolutions.com",
                "Spartan App Solutions Inc"
              )
            }
          >
            <FaDownload />
            <span>Save Contact</span>
          </SaveContactButton>
        </ContactCard> */}
      </ContactSection>
      <Heading>
      <span>  --- Company Infos ---</span>
      </Heading>
      {/* Website */}
      <ContactLink href="https://www.spartanappsolutions.com">
        <FaGlobe /> <span>www.spartanappsolutions.com</span>
      </ContactLink>

      {/* Social Links */}
      <SocialContainer>
        <SocialLink
          href="https://www.facebook.com/SpartanAppSolutions/"
          target="_blank"
          aria-label="Facebook"
        >
          <FaFacebook />
        </SocialLink>
        <SocialLink
          href="https://www.linkedin.com/company/spartan-app-solutions/"
          target="_blank"
          aria-label="LinkedIn"
        >
          <FaLinkedin />
        </SocialLink>
        <SocialLink
          href="https://www.instagram.com/SpartanAppSolutions/"
          target="_blank"
          aria-label="Instagram"
        >
          <FaInstagram />
        </SocialLink>
      </SocialContainer>
    </Container>
  );
};
